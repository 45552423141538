@import 'paper-kit/variables';
@import 'paper-kit/mixins';

// Plugins CSS
@import "paper-kit/plugins/plugin-bootstrap-switch";
@import "paper-kit/plugins/plugin-nouislider";
@import "paper-kit/plugins/plugin-datepicker";

// Core CSS
@import "paper-kit/buttons";
@import "paper-kit/typography";
@import "paper-kit/misc";

// components
@import "paper-kit/social-buttons";
@import "paper-kit/navbars";
@import "paper-kit/modal";
@import "paper-kit/carousel";
@import "paper-kit/footers";

// Cards
@import "paper-kit/cards";

// example pages and sections
@import "paper-kit/examples";
@import "paper-kit/sections";


@import "paper-kit/responsive";

// React Differences
@import "react/react-differences.scss";
